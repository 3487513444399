.lists{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.lists .checkBox{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    /*justify-content: space-between;*/
    width: 100%;
}

.checkBoxLeads{

}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400&display=swap');
/* Language: CSS */

:root {
  /* Dark theme */
  --system-ui: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --primary: #000000;
  --primary-2: #111;
  --secondary: #ffffff;
  --secondary-2: #f1f3f5;
  --hover: rgba(255, 255, 255, 0.075);
  --hover-1: rgba(255, 255, 255, 0.15);
  --hover-2: rgba(255, 255, 255, 0.25);

  --text-base: white;
  --text-primary: white;
  --text-secondary: black;

  --accents-0: #212529;
  --accents-1: #343a40;
  --accents-2: #495057;
  --accents-3: #868e96;
  --accents-4: #adb5bd;
  --accents-5: #ced4da;
  --accents-6: #dee2e6;
  --accents-7: #e9ecef;
  --accents-8: #f1f3f5;
  --accents-9: #f8f9fa;

  --red: #da3c3c;
  --cyan: #29BC9B;


  --darkBackgroundMain: #1d1d1d;
  --darkTextColor: #666;
  --darkTextColorLight: white;
  --darkAccents-1: #fafafa;
  --darkAccents-2: #eaeaea;

  --darkAccents-3: #999;
  --darkAccents-4: #888;
  --darkAccents-5: #666;
  --darkAccents-6: #444;
  --darkAccents-7: #222;
  --darkAccents-8: #131313;
  --darkAccents-9: #181818;
  --black: #000;

  --backgroundColor: #000;
  --backgroundColorSecondary: #111;
  --backgroundColorLight: white;

  --backgroundColorOpacity20: rgba(0, 0, 0, 0.2);
  --backgroundColorOpacity40: rgba(0, 0, 0, 0.4);
  --backgroundColorOpacity60: rgba(0, 0, 0, 0.6);
  --backgroundColorOpacity80: rgba(0, 0, 0, 0.8);
  --backgroundColorOpacity90: rgba(0, 0, 0, 0.9);
  --backgroundColorOpacity00: rgba(0, 0, 0, 0);

  --mainColor: #0070f3;
  --mainColorLight: #cee4fe;
  --mainColor2: #075cc0;
  --secondColor: #caf7e2;
  --thirdColor: #daf5ff;

  --violetLight: #8A63D2;
  --violet: #7928CA;
  --violetDark: #4C2889;

  --greenUltraLight: #c7f3e8;
  --greenLight: #29BC9B;
  --green: #29BC9B;
  --greenDark: #37b679;
  --greenVeryDark: #085430;

  --warningColor: #f5a623;
  --warningColorLight: rgba(245, 166, 35, 0.3);
  --errorColor: #e00;

  --newErorrColorUltraLight: #2b2b2b;
  --newErrorColorLight: #5c5c5c;
  --newErrorColor: #ff6b6b;

  --newWarningColorUltraLight: #2b2b2b;
  --newWarningColorLight: #5c5c5c;
  --newWarningColor: #ffb347;

  --newInfoColorUltraLight: #2b2b2b;
  --newInfoColorLight: #5c5c5c;
  --newInfoColor: #4a90e2;

  --newSuccessColorUltraLight: #2b2b2b;
  --newSuccessColorLight: #5c5c5c;
  --newSuccessColor: #00b894;

  --cardRadius: 7px;

  --maxWidth: 1000px;
  --maxWidthPlus: 1150px;
  --maxWidthPlusPlus: 1250px;
  --heightNavbar: 67.5px;

  --gradient-start: #9E6EEB;
  --gradient-end: #DB47BD;
  --gradient-alternate-start: #29B5E8;
  --gradient-alternate-end: #00EB88;
  --path-length: 655.412;
  --dash-offset: 675;

  --filter: 100%;
  --twitterColor: #1DA1F3;
  --linkedinColor: #0a66c2;
}


html.light{
  --primary: #000000;
  --primary-2: #111;
  --secondary: #ffffff;
  --secondary-2: #f1f3f5;
  --hover: rgba(0, 0, 0, 0.075);
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);

  --text-base: black;
  --text-primary: white;
  --text-secondary: white;

  --accents-0: #212529;
  --accents-1: #343a40;
  --accents-2: #495057;
  --accents-3: #868e96;
  --accents-4: #adb5bd;
  --accents-5: #ced4da;
  --accents-6: #dee2e6;
  --accents-7: #e9ecef;
  --accents-8: #f1f3f5;
  --accents-9: #f8f9fa;

  --red: #da3c3c;
  --cyan: #29BC9B;


  --darkBackgroundMain: #1d1d1d;
  --darkTextColor: #666;
  --darkTextColorLight: white;
  --darkAccents-1: #212529;
  --darkAccents-2: #212529;
  --darkAccents-3: #343a40;
  --darkAccents-4: #495057;
  --darkAccents-5: #bbbdc0;
  --darkAccents-6: #dee2e6;
  --darkAccents-7: #e9ecef;
  --darkAccents-8: #f1f3f5;
  --darkAccents-9: #f6f6f8;
  --black: #000;

  --backgroundColor: white;
  --backgroundColorSecondary: #fbfbfb;
  --backgroundColorLight: #000;

  --backgroundColorOpacity20: rgba(255, 255, 255, 0.2);
  --backgroundColorOpacity40: rgba(255, 255, 255, 0.4);
  --backgroundColorOpacity60: rgba(255, 255, 255, 0.6);
  --backgroundColorOpacity80: rgba(255, 255, 255, 0.8);
  --backgroundColorOpacity90: rgba(255, 255, 255, 0.9);
  --backgroundColorOpacity00: rgba(255, 255, 255, 0);

  --filter: 0%;


  --newErorrColorUltraLight: #faefeb;
  --newErrorColorLight: #e4cdc9;
  --newErrorColor: #fb5755;

  --newWarningColorUltraLight: #fef8ea;
  --newWarningColorLight: #f0dfc3;
  --newWarningColor: #ffc21c;

  --newInfoColorUltraLight: #e7effa;
  --newInfoColorLight: #cad9ec;
  --newInfoColor: #4487d3;

  --newSuccessColorUltraLight: #f1f7f3;
  --newSuccessColorLight: #d7e4da;
  --newSuccessColor: #4ddc69;

}


html{
  height: 100VH;
  /*background-color: var(--darkAccents-8) !important;*/
  font-family: var(--system-ui);
}

body{
  font-family: var(--system-ui);
}

html.light body{
  background: #fbfbfb;
}

html body{
  margin: 0;
  background: #111;
}

a{
  color: var(--mainColor);
  text-decoration: none;
}

.main-bg{
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100VH;
  overflow: auto;
  max-height: 100VH;
  /*background-color: var(--backgroundColor);*/
}

.input-wrapper{
  background-color: var(--backgroundColor);
}

#__next{
  overflow-x: unset !important;
}


.rdtPicker{
  background-color: var(--darkAccents-8) !important;
  border-radius: 7px;
  box-shadow: none !important;
  border: none !important;
}

.rdtCounter .rdtCount{
  height: unset !important;
}

.rdtCounter .rdtBtn{
  transition: 0.3s;
  border-radius: 5px;
}

.rdtCounter .rdtBtn:hover{
  background-color: var(--darkAccents-7) !important;
  color: var(--text-base) !important;
}
.ProseMirror p.is-editor-empty:first-child::before {
  color: var(--darkAccents-4);
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}


.ProseMirror{
  height: 98%;
  box-sizing: border-box;
  outline: none;
}

  .ProseMirror > * + * {
    margin-top: 0em;
  }

.ProseMirror ul, .ProseMirror ol {
  padding: 0 1rem !important;
  margin: 8pt 8pt 8pt 16pt;
}

.ProseMirror ul {
  list-style: disc;
}


.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror p{
  margin: 0;
}

.ProseMirror pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: var(--system-ui);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0D0D0D, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(#0D0D0D, 0.1);
  margin: 2rem 0;
}

.ProseMirror .variable {
  font-weight: 500;
  color: #7828c8;
  white-space: nowrap;
  background-color: #eadcf8;
  padding: 1px 3px;
  border-radius: 2px;
}
.ProseMirror .variable.user{
  background: var(--newInfoColorUltraLight);
    color: var(--newInfoColor);
}

.ProseMirror .variable[data-sexy-name]{
  font-size: 0;
  background-color: transparent;
}

.ProseMirror .variable[data-sexy-name]:before {
  content: attr(data-sexy-name);
  font-size: initial;
  font-weight: 500;
  color: #7828c8;
  white-space: nowrap;
  background-color: #eadcf8;
  padding: 1px 3px;
  border-radius: 2px;
}


.ProseMirror .variable[data-sexy-name]:after {
  font-size: initial;
  font-weight: 500;
  white-space: nowrap;
}


/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display:none;
}


.emoji-mart-category-list li::before {
  content: none !important;
}

#canvas3d{
  width: 100% !important;
  height: 100% !important;
}

#learnd_icon_widget{
  display: none !important;
}


.react_select_container{
  width: 100%;
}

.react_select_container .break__control{
  background-color: var(--backgroundColor);
  color: var(--text-base);
  border: 1px solid var(--darkAccents-6);
}

.react_select_container .break__control--is-focused{
  background-color: var(--backgroundColor);
  color: var(--text-base);
  border: 1px solid var(--text-base);
  box-shadow: none;
}

.react_select_container .break__placeholder{
  color: var(--text-base);
}

.react_select_container .break__menu{
  background-color: var(--darkAccents-8);
  border: 1px solid var(--darkAccents-7);
  z-index: 10;
}

.react_select_container .break__single-value{
  color: var(--text-base);
}
.react_select_container .break__input-container{
  color: var(--text-base);
}

.react_select_container .break__menu-list{
  color: var(--text-base);
}
.react_select_container .break__menu-list .break__option{
  transition: 0.1s;
}
.react_select_container .break__menu-list .break__option:hover{
  background-color: var(--darkAccents-7);
}

.react_select_container .break__menu-list .break__option--is-selected{
  background-color: var(--darkAccents-7);
}

.react_select_container .break__menu-list .break__option--is-focused{
  background-color: var(--darkAccents-7);
}

.CSVImporter_Importer{
  width: 100%;
  height: 100%;
  position: relative !important;
}

.CSVImporter_FileSelector {
  border: 2px dashed var(--darkAccents-5) !important;
  padding: 4em;
  margin: 20px !important;
  border-radius: 0.4em;
  background: var(--darkAccents-6) !important;
  text-align: center;
  color: var(--darkAccents-4) !important;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}

.CSVImporter_FileSelector:hover{
  border: 2px dashed var(--text-base) !important;
}

.CSVImporter_ImporterFrame{
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.CSVImporter_ImporterFrame .CSVImporter_ImporterFrame__footer{
  padding: 20px !important;
  margin-top: auto;
}

.CSVImporter_IconButton{
  color: var(--text-base) !important;
  /*background: blue !important;*/
  filter: invert(var(--filter));
  border-radius: 5px !important;
  transition: 0.2s;
}

.CSVImporter_IconButton:hover{

}

.CSVImporter_ImporterFrame__header{
  margin: 0 !important;
}

.CSVImporter_ImporterFrame__headerTitle{
  color: var(--text-base) !important;
}

.CSVImporter_FormatPreview__header{
  color: var(--darkAccents-3) !important;
}

.CSVImporter_FormatRawPreview__scroll{
  background-color: var(--backgroundColor) !important;
  max-height: 200px;
  height: inherit !important;
  margin-bottom: 30px !important;
  border: 1px solid var(--darkAccents-7) !important;
}

.CSVImporter_FormatRawPreview__pre{
  border: none;
  overflow: initial !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: var(--darkAccents-4);
}

.CSVImporter_FormatDataRowPreview{
  max-height: 300px !important;
  overflow: auto !important;
  border: 1px solid var(--darkAccents-7) !important;
  border-radius: 5px !important;
}

.CSVImporter_FormatPreview__headerToggle{
  color: var(--text-base) !important;

}

.CSVImporter_ColumnDragTargetArea__boxPlaceholderHelp{
  color: var(--darkAccents-4) !important;
}

.CSVImporter_ColumnDragCard[data-dummy=true]{
  background-color: var(--darkAccents-8) !important;
  color: var(--darkAccents-4) !important;
  border-radius: 5px !important;
  border: 1px dashed var(--darkAccents-6) !important;

}
.CSVImporter_ColumnDragTargetArea__boxLabel{
  color: var(--text-base) !important;
  font-weight: 400 !important;
}

.CSVImporter_ColumnDragSourceArea{
  margin-top: 0 !important;
  background-color: var(--darkAccents-8) !important;
  border-bottom: 1px solid var(--darkAccents-7);
  border-top: 1px solid var(--darkAccents-6);
  padding: 20px 0 !important;
  margin-bottom: 0 !important;
}

.CSVImporter_ColumnDragSourceArea .CSVImporter_ColumnDragCard{
  background-color: var(--darkAccents-8) !important;
  border: 1px solid var(--darkAccents-7) !important;
}

.CSVImporter_ColumnDragSourceArea .CSVImporter_ColumnDragCard .CSVImporter_ColumnDragCard__cardValue {
  color: var(--darkAccents-3) !important;
}
.CSVImporter_ColumnDragCard{
  padding: 5px !important;
  background-color: var(--mainColor) !important;
  overflow: hidden !important;
}

.CSVImporter_ColumnDragCard .CSVImporter_ColumnDragCard__cardValue {
  color: var(--accents-5) !important;
  max-width: 190px !important;
}

.CSVImporter_ColumnDragObject{
  z-index: 100;
}

.CSVImporter_ColumnDragCard__cardHeader>b{
  background-color: transparent !important;
}

.CSVImporter_ColumnDragCard__cardHeader{
  margin: 0 !important;
  background-color: transparent !important;
  display: none !important;
  border-bottom: 1px solid var(--darkAccents-6);
}
.CSVImporter_ColumnDragCard__cardValue{
  text-align: center;
  color: var(--darkAccents-3) !important;
  padding: 0 10px !important;
}

.CSVImporter_ColumnDragCard[data-shadow=true]{
  background-color: var(--darkAccents-7) !important;
  border: 1px solid var(--darkAccents-6) !important;
  color: var(--darkAccents-4) !important;
}

.CSVImporter_ColumnDragCard[data-shadow=true] .CSVImporter_ColumnDragCard__cardHeader{
  border-bottom: 1px solid transparent;
}

.CSVImporter_ColumnDragCard__cardValue[data-header=true]{
  color: var(--text-base) !important;
  font-size: 14px !important;
}

.CSVImporter_ColumnDragCard .CSVImporter_ColumnDragCard__cardValue[data-header=true]{
  color: white !important;
}

.CSVImporter_ColumnDragSourceArea__box .CSVImporter_ColumnDragCard__cardValue[data-header=true]{
  color: var(--text-base) !important;
}

.CSVImporter_TextButton{
  background-color: var(--mainColor) !important;
  border: 1px solid var(--mainColor) !important;
  color: white !important;
  border-radius: 5px !important;
  padding: 10px 40px !important;
  transition: 0.2s;
}

.CSVImporter_TextButton:hover{
  background-color: transparent !important;
  border: 1px solid var(--mainColor) !important;
  color: var(--mainColor) !important;
}

.CSVImporter_ImporterFrame__headerCrumbSeparator>span{
  filter: invert(100%);
}
.CSVImporter_ImporterFrame__headerSubtitle{
  color: var(--text-base) !important;
}

.CSVImporter_ImporterFrame__footerError{
  /*flex: 1 !important;*/
  color: var(--red) !important;
}

.CSVImporter_ProgressDisplay__status{
  color: white !important;
}

.CSVImporter_ProgressDisplay__count{
  color: var(--darkAccents-3) !important;
}

.CSVImporter_ProgressDisplay__progressBar{
  height: 10px !important;
  border-radius: 10px !important;
  background-color: var(--darkAccents-7) !important;
  /*border: 1px solid var(--darkAccents-6) !important;*/
}

.CSVImporter_ProgressDisplay__progressBarIndicator{
  background-color: var(--mainColor) !important;
  border-radius: 10px !important;
  height: 10px !important;
}

.CSVImporter_ColumnDragSourceArea__pageIndicator{
  color: var(--darkAccents-4) !important;
  top: -10px !important;
}

.CSVImporter_ColumnDragSourceArea__page{
  padding-top: 15px !important;
}

.CSVImporter_ColumnDragCard{
  max-height: 75px !important;
}

.CSVImporter_ColumnDragTargetArea__boxValue{
  max-height: 75px !important;
  border: 1px solid transparent ;
}

.CSVImporter_ColumnDragCard__cardValue:nth-child(n+3){
  display: none !important;
}

.CSVImporter_ColumnDragTargetArea{
/*  GRID 6 column*/
  display: grid !important;
  grid-template-columns: repeat(4, 1fr) !important;
  grid-gap: 10px !important;
  padding: 20px !important;
  margin: 0 !important;
}

.CSVImporter_ColumnDragTargetArea__box{
  padding: 0 !important;
  width: 100% !important;
}


.CSVImporter_FormatPreview__mainResultBlock{
  padding: 20px !important;
}
.navTabs header{
  padding: 0 4px !important;
}
.navTabs .content{
  display: none;
}

#geist-ui-toast div{
  z-index: 99999;
}

#geist-ui-dropdown div{
  z-index: 99999;
}


ul li:before{
  content: none !important;
}


div[data-popper-placement] div{
  box-sizing: border-box !important;
  font-family: var(--system-ui);
}

.cm-editor .cm-scroller{
  background-color: var(--backgroundColor);
}

.cm-editor .cm-gutters{
    background-color: var(--darkAccents-8);
}

.cm-editor .cm-tooltip{
  background-color: var(--darkAccents-8);
  /*color: var(--text-base);*/
}

.cm-editor .cm-gutter.cm-lineNumbers{
  min-width: 27.2px;
}

.scroll-container{
  border-bottom: none !important;
}


.rc-slider-track{
  height: 6px !important;
  background-color: var(--backgroundColorLight) !important;
}

.rc-slider-rail{
  height: 6px !important;
}

.rc-slider-step{
  height: 6px !important;
  border-radius: 6px;
  overflow: hidden;
}

.rc-slider-dot{
  bottom: 0px !important;
  background-color: black !important;
  opacity: 0.3 !important;
  border: 2px solid black !important;
  border-radius: 0px !important;
  height: 6px !important;
    width: 4px !important;

}

.rc-slider-mark .rc-slider-mark-text{
  display: none;
}

.rc-slider-mark .rc-slider-mark-text:first-child, .rc-slider-mark .rc-slider-mark-text:last-child{
  display: block;
  word-break: keep-all;
  transform: translateX(-5%) !important;
}

.rc-slider-mark .rc-slider-mark-text:last-child{
  display: block;
  word-break: keep-all;
  width: fit-content;
  transform: translateX(-90%) !important;
}


.rc-slider-dot-active{
  background-color: transparent !important;
  border: 2px solid transparent !important;
}

.rc-slider-handle{
  border: 2px solid var(--text-secondary) !important;
  background-color: var(--backgroundColorLight) !important;
  opacity: 1 !important;
  margin-top: -4px !important;
  box-shadow: 0 0px 10px rgb(0 0 0 / 30%) !important;
}


.crisp-client{
  z-index: 9999;
  position: relative;
}

.wrap{
    display: flex;
    flex-direction: row;
    color: var(--darkAccents-3);
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    font-weight: 500;
}

.wrap.invalid{
    color: var(--errorColor);
}

.wrap .textSeparator{
    font-size: 13px;
    line-height: 13px;
    margin: 0 3px;
}

.wrap .textLengthWrap{
    font-size: 13px;
    line-height: 13px;
}


.wrap .textMaxLenghtWrap{
    font-size: 13px;
    line-height: 13px;
}


.wrapLine{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.modalTitle{
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}


.modalButton.iconOnly{
    background: transparent;
}

.modalButton:hover{
    background: var(--mainColor2);
}

.modalButton.iconOnly:hover{
    background: var(--darkAccents-6);
}

.modalButton:active{
    transform: scale(0.95);
}

.modalButton .modalButtonIcon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalButton .modalButtonIcon svg{
    fill: white;
}

.modalButton.iconOnly .modalButtonIcon svg{
    fill: var(--mainColor);
}

.modalButton .modalButtonIcon .modalButtonIconPlus{
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    color: black;
    top: 0;
    z-index: 10;
}

.modalButton .modalButtonText{
    margin-left: 5px;
}

.popoverWrap{
    /*padding: 20px;*/
    min-width: 450px;
}


.form {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.form .line{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.form .actions{
    margin-top: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.form .labelRight{
    font-size: 12px;
    color: var(--mainColor);
    cursor: pointer;
}





.quicks{
    display: flex;
    margin-top: 10px;
    width: 100%;

}

.quicks .quick{
    border-radius: 5px;
    font-size: 13px;
    flex: 1;
    min-width: 80px;
    line-height: 13px;
    /*border: 1px solid var(--darkAccents-5);*/
    padding: 2px 3px;
    display: flex;
    color: var(--text-base);
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-right: 5px;
    transition: 0.2s;
}

.quicks .quick:hover{
    background-color: var(--darkAccents-7);
}

.quicks .quick:active{
    transform: scale(0.95);
}


.showMore{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 20px;
    padding: 2px 10px;
    color: var(--darkAccents-4);
}

.showMore:hover{
    background: var(--darkAccents-7);
}

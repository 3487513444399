
.wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}


.wrap .logo{
    /*margin-bottom: 10VH;*/
}


.wrap .waitingText{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
}


.statusPage{
    font-size: 16px;
    font-weight: 500;
    padding: 5px 15px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--darkAccents-7);
    background: var(--backgroundColor);
    border-radius: 8px;
    margin-top: 15px;
}


.statusPage:hover{
    background: var(--darkAccents-7);
}

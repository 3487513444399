.wrapper{
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    align-items: center;
}

.wrapper.noPadding{
    padding-bottom: 0;
}


.editor{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--backgroundColor);
    border-radius: 7px;
    /*border-radius: 8px;*/
    /*overflow: hidden;*/
    border: 1px solid var(--darkAccents-7);
}

.editor.noBorder{
    border: none;
}


.buttonGroup{
    padding:10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid var(--darkAccents-7);
    /*background-color: blue;*/
}

.buttonGroup.smallMode{
    gap: 2px;
    z-index: 15;
    border-radius: 10px;
    border: 1px solid var(--darkAccents-7);
    background: var(--backgroundColor);
}

.buttonGroup .group button:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}
.smallMode .buttonGroup{
    align-items: flex-start;
    justify-content: flex-start;
}

.smallMode .buttonGroup .spacer{
    width: 0px;
}

.smallMode p{
    font-size: 13px;
}

.smallMode span::before{
    font-size: 13px !important;
}

.buttonGroup button{
    background: transparent;
    font-size: 15px;
    line-height: 15px;
    display: flex;
    align-items: center;
    font-weight: 700;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    transition: 0.2s;
    border:1px solid transparent;
    border-radius: 5px;
    color: var(--darkAccents-4);
    text-transform: uppercase;
}

.buttonGroup button:hover{
    background-color: var(--darkAccents-8);
    border: 1px solid var(--text-base);
}

.buttonGroup button svg{
    height: 15px;
    width: 15px;
    color: var(--darkAccents-4);
    transition: 0.2s;
}
.smallMode.wrapper{
    padding-bottom: 0;
}
.smallMode .buttonGroup button svg{
    height: 12px;
    width: 12px;
}

.smallMode .editorWrap{
    max-height: 60VH;
    min-height: 20VH;
}

.buttonGroup button .fillSvg path{
    fill: var(--darkAccents-4);
}

.buttonGroup button .strokeSvg path, .buttonGroup button .strokeSvg polyline, .buttonGroup button .strokeSvg line{
    stroke: var(--darkAccents-4);
}

.buttonGroup button:hover svg{
    color: var(--text-base);
}

.smallMode .buttonGroup button{
    font-size: 12px;
    line-height: 12px;
    padding: 3px;
}


.commands{
    overflow: auto;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--darkAccents-7);
    border-radius: 8px;
    background-color: var(--backgroundColor);
    box-shadow: 0 8px 30px rgba(0,0,0,.04);
    padding: 5px;
    margin: 0;
}

.commands.isUser{
    padding: 0;
    border-radius: 5px;
}

.commands .head{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-bottom: 1px solid var(--darkAccents-5);
}

/*.commands .command{*/
/*    display: flex;*/
/*    padding: 7px 7px 7px 7px;*/
/*    flex-direction: column;*/
/*    margin: 0;*/
/*    box-sizing: border-box;*/
/*    border-radius: 0px;*/
/*    max-width: 400px;*/
/*    text-overflow: ellipsis;*/
/*    cursor: pointer;*/
/*    display: -webkit-box;*/
/*    margin-bottom: 1px;*/
/*    -webkit-line-clamp: 1;*/
/*    -webkit-box-orient: vertical;*/
/*    background-color: var(--backgroundColor);*/
/*}*/

.command::before{
    content: '';
}

.commands .command:last-child{
    border-bottom: none;
}

.commands .command.active{
    background-color: var(--darkAccents-8);
}

.commands .command:hover{
    /*background-color: var(--darkAccents-8);*/
}

.commands .command:focus{
    outline: 1px solid var(--mainColor);
}

.commands .command.isUser{

}

.command.isUser .content h5{
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    margin: 0;
    color: var(--text-base);
}

.command.isUser .content span{
    margin-left: 5px;
    font-size: 12px;
    color: var(--darkAccents-4);
    line-height: 12px;
}

.commands .command.isUser.active{
    background-color: var(--mainColor2);
    color: white;
}

.commands .command.isUser.active h5{
    color: white;
}

.commands .command.isUser.active span{
    color: white;
}


.commands .command .content{
    display: flex;
    flex-direction: column;
}

.command.isUser .content{
    flex-direction: row;
    align-items: center;
}

.command.isUser .icon{
    margin-right: 5px;
}

.command .content h5{
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    margin: 0;
    color: var(--text-base);
}

.command .content span{
    font-size: 12px;
    line-height: 12px;
    color: var(--darkAccents-4);
}



.buttonGroup .group{
    display: flex;

}

.buttonGroup .spacer{
    width: 30px;
    /*background-color: blue;*/
}

.shortCuts{
    background-color: var(--darkAccents-8);
    display: flex;
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    padding-right: 80px;
    justify-content: space-between;
    border-top: 1px solid var(--darkAccents-7);
}

.shortCuts .actionGroup{
    display: flex;
}

.shortCuts .shortcut{
    margin-right: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: var(--darkAccents-4);
}


.shortCuts .save{
    flex: 1;
    display: flex;
    z-index: 1;
    /*justify-content: flex-end;*/
    align-items: center;
}


.wrapEditor{
    width: 100%;
    display: flex;
}

.sequenceMode .wrapEditor{
    border: 1px solid var(--darkAccents-7);
    border-radius: 10px;
    overflow: hidden;
}

.wrapEditor .scoring{
    display: flex;
    border: 1px solid var(--darkAccents-7);
    border-radius: 7px;
    min-width: 250px;
    width: 100%;
    background: var(--backgroundColor);
    max-width: 270px;
    margin-left: 10px;
    overflow: auto;
}

.wrapHead{
    width: 100%;
    flex: 1;
}

.htmlEditor{
    position: relative;
    /*padding: 10px 0;*/
    width: 100%;
    display: flex;
    flex-direction: column;
    /*border: 2px solid red;*/
}

.htmlEditor .warnings{
    display: flex;
    position: sticky;
    bottom: 0;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid var(--darkAccents-6);
}

.htmlEditor .warning{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    padding: 5px;
    background-color: var(--newWarningColorLight);
}

.htmlEditor p{
    min-height: 20px;
}

.htmlEditor>div:first-child{
    flex: 1;
}

.editorWrap{
    max-height: 450px;
    overflow: auto;
    width: 100%;
    flex-direction: column;
    position: relative;
    display: flex;
    min-height: 420px;
    padding: 0 10px;
    box-sizing: border-box;
}

.editorWrap.htmlMode{
    padding: 0;
}

.editorWrapLoader{
    display: flex;
    align-items: center;
    justify-content: center;
}

.editorWrap.noMaxHeight{
    min-height: initial;
    padding: 0 10px;
}

.editorWrap.cursor{
    cursor: pointer;
}

.floatRight{
    right: 0;
    bottom: 0;
    z-index: 0;
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.hyperLinkMenu{
    height: 53px;
    align-items: center;
    overflow: hidden;
    display: flex;
    padding: 10px;
    background-color: var(--darkAccents-8);
}

.variableShow{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    border: 1px solid var(--darkAccents-7);
    border-radius: 8px;
    background: var(--backgroundColor);
}

.variableShow .label{
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
}

.variableShow > div{
    margin: 5px !important;
}


.variableShow .badge{
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    font-family: var(--system-ui);
    white-space: pre;
    overflow: auto;
    line-height: 1.5;
    text-align: left;
    position: relative;
    max-width: 100%;
    color: #888;
    background-color: var(--backgroundColor);
    border: 1px solid var(--darkAccents-7);
    border-radius: 5px;
    --snippet-font-size: calc(0.609375 * 16px);
    --snippet-padding-top: calc(0.5002500000000001 * 16px);
    font-size: var(--snippet-font-size);
    width: initial;
    height: auto;
    margin: 0 0 0 0;
}

.badge .badgeName{
    margin-left: 10px;
    margin-right: 2px;
}
.badge .copy{
    /*width: 20px;*/
    /*height: 20px;*/
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
}

.badge .copy:hover{
    background-color: var(--darkAccents-7);
}

.variableShow .badge:hover{
    color: var(--text-base) !important;
    border: 1px solid var(--text-base);
}

.test{
    position: relative;
    max-width: 100%;
    color: #888;
    background-color: var(--backgroundColor);
    border: 1px solid #888;
    border-radius: 5px;
    --snippet-font-size: calc(0.609375 * 16px);
    --snippet-padding-top: calc(0.5002500000000001 * 16px);
    font-size: var(--snippet-font-size);
    width: initial;
    height: auto;
    padding: calc(0.5002500000000001 * 16px) calc(2.00025 * 16px) calc(0.5002500000000001 * 16px) calc(0.5002500000000001 * 16px);
    margin: 0 0 0 0;
    /*border-radius: 5px;*/
    font-family: var(--system-ui);
    white-space: pre;
    overflow: auto;
    line-height: 1.5;
    text-align: left;
}

.header{
    display: flex;
    /*padding: 15px;*/
    min-height: 43px;
    align-items: center;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    overflow: hidden;
    border-bottom: 1px solid var(--darkAccents-7);
    background-color: var(--darkAccents-8);
}

.header .subjectLabel{
    font-size: 16px;
    color: var(--darkAccents-4);
    margin-right: 0px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header .subjectPost{
    font-size: 16px;
    color: var(--darkAccents-5);
    margin-right: 0px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
}



.header .subjectName{
    flex: 1;
}
.header .subjectName.cursor input{
    cursor: pointer;
}

.header .subjectInput{
    font-size: 16px;
    font-weight: 600;
    border:none;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--backgroundColor);
    padding: 5px 10px;
}

.smallMode .header{
    align-items: inherit;
}

.smallMode .header .subjectInput{
    font-size: 13px;
    height: 100%;
}

.smallMode .header .subjectLabel{
    font-size: 13px;
}

.stepInfo{
    height: 50px;
    display: flex;
    /*padding: 10px 15px;*/
    width: 100%;
    overflow: hidden;
    background: var(--backgroundColor);
    border: 1px solid var(--darkAccents-7);
    border-radius: 8px;
    align-items: center;
    margin-bottom: 15px;
}

.stepInfo .stepInfoTitle{
    font-size: 26px;
    width: 100%;
    line-height: 26px;
    height: 100%;
    font-weight: 600;
}

.stepInfo .invisibleInput{
    background: transparent;
    border: none;
    width: 100%;
    font-weight: 600;
    height: 100%;
    transition: 0.3s;
    padding: 10px;
}

.stepInfo .editMode{
    height: 100%;
    align-items: center;
    display: flex;
    flex: 1;
}

.stepInfo .stepName{
    height: 100%;
    /*cursor: pointer;*/
    align-items: center;
    /*justify-content: center;*/
    display: flex;
    transition: 0.3s;
    padding: 10px;
    position: relative;
}

.stepInfo .stepName .floatRight{
    position: absolute;
    right: 10px;
    bottom: inherit;
}

.stepInfo .stepName:hover{
    /*background-color: var(--darkAccents-9);*/
}

.bubbleMenu{
    padding: 5px 10px;
    background-color: var(--darkAccents-8);
    border: 1px solid var(--darkAccents-7);
    border-radius: 5px;
}

.empty{
    padding: 10px;
    text-align: center;
    color: var(--darkAccents-4);
}

.emptyUser{
    padding: 5px 10px;
    text-align: center;
    color: var(--darkAccents-4);
}


.wrapper.sequenceMode{
    height: 100%;
}
.wrapper.sequenceMode .scoring{
    border:none;
    border-radius: 0;
    margin:0;
    background: var(--darkAccents-9);
}

.wrapper.sequenceMode .variableShow{
    border:none;
}

.wrapper.sequenceMode .editor{
    height: 100%;
    border: none;
}
.wrapper.sequenceMode .wrapEditor{
    flex: 1;
    overflow: auto;
}
.wrapper.sequenceMode .editor .editorWrap{
    max-height: none;
    height: 100%;
    min-height: inherit;
}

.wrapper.sequenceMode .shortCuts{
    border: 1px solid var(--darkAccents-7);
    border-radius: 10px;
    background: var(--backgroundColor);
    margin-top: 10px;
    /*border-top: 1px solid var(--darkAccents-6);*/
}

.wrapper.sequenceMode .header .subjectLabel{
    display: none;
}

.wrapper.sequenceMode .header .subjectInput{
    font-weight: 600;
    font-size: 18px;
    padding: 10px 10px;
}



.fixedVariables{
    margin: 10px 0 ;
    margin-top: auto;
    display: flex;
    padding: 7px 10px;
    border-radius: 10px;
    align-items: center;
    border: 1px solid var(--darkAccents-8);
    background: var(--darkAccents-9);
    justify-content: flex-start;
}

.fixedVariables .fixedVariablesLabel{
    font-family: ui-monospace,SFMono-Regular,SF Mono,Menlo,Consolas,Liberation Mono,monospace;
    font-size: 12px;
    word-wrap: normal;
    white-space: pre;
    color: var(--darkAccents-4);
}

.smallMode .fixedVariables{
    padding: 5px;
}

.smallMode .fixedVariables .fixedVariablesLabel{
    font-size: 10px;
}


@media (max-height: 750px){
    .smallMode .editorWrap{
        max-height: 200px;
    }
}

@media (max-height: 700px){
    .smallMode .editorWrap{
        max-height: 150px;
    }
}


.tagsWrap{
    display: flex;
    overflow-x: auto;
}

.tagsWrap::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.addTag{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: var(--text-base);
    border-radius: 5px;
    padding: 2px 5px;
    margin-right: 5px;
    cursor: pointer;
    border: 2px dotted var(--darkAccents-6);
}

.statusTag{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 2px 8px;
    color: var(--text-base);
    border: 1px solid var(--darkAccents-5);
    background: var(--backgroundColorSecondary);
    cursor: pointer;
    max-width: 130px;
    font-weight: 500;
    word-break: break-all;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tagsWrap .statusTag:not(:last-child){
    margin-right: 5px;
}


.statusTag.emptyStatus{
    padding: 2px 8px;
    border: 1px dashed var(--darkAccents-5);
    color: var(--darkAccents-3);
    background: transparent;
}

.statusTag.fullHeight{
    height: 100%;
}

.statusTag .statusEmoji{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2px;
}

.statusTag .statusText{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.statusTag .statusIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.statusLists{
    border-radius: 15px;
    min-width: 200px;
    display: flex;
    max-height: 500px;
    overflow: auto;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.statusLists .loaderWrap{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    justify-content: center;
}

.statusLists .statusListHeader{
    padding: 10px 10px 5px 10px;
    font-size: 12px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    background: var(--backgroundColor);
    color: var(--darkAccents-3);
    text-align: left;
}

.statusLists .statusList {
    display: flex;
    align-items: center;
    /*background: blue;*/
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    padding: 4px 10px;
}

.statusLists .statusList:hover{
    background: var(--darkAccents-8);
}

.statusLists .statusList.empty{
    font-size: 12px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--darkAccents-7);
    color: var(--darkAccents-4);
}

.statusLists .statusList.unselect{
    font-size: 12px;
    align-items: center;
    justify-content: center;
    /*border-bottom: 1px solid var(--darkAccents-7);*/
    color: var(--darkAccents-4);
}

.statusList .statusListAction{
    display: flex;
    color: var(--darkAccents-4);
    margin-left: auto;
    padding: 4px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 4px;
}

.statusList .statusListAction:hover{
    background: var(--darkAccents-6);
    color: black;
}

.statusList .inputAction input{
    border: none;
    background: none;
    padding: 0;
    font-size: 12px;
    min-width: 120px;
}

.inputActionColor{
    width: 20px;
    cursor: pointer;
    height: 20px;
    border-radius: 5px;
    border: 1px solid var(--darkAccents-4);
}

.statusEditWrap{
    align-items: center;
    display: flex;
}

.statusEditWrap .inputAction{
    /*background: red;*/
    max-width: 130px;
}


.menuList{
    padding: 5px 0;
    display: flex;
    flex-direction: column;
}

.menuList .menuListItem{
    display: flex;
    align-items: center;
    transition: 0.2s;
    padding: 5px 6px;
    cursor: pointer;
}

.menuList .menuListItem:hover{
    background: var(--darkAccents-8);
}

.menuList .menuListItem .menuListItemIcon{
    margin-right: 5px;
    display: flex;
    align-items: center;
}

.menuListItem .menuListItemText{
    font-size: 13px;
    line-height: 13px;
}


.menuListItem.error{
    color: var(--errorColor);
    font-weight: 600;
}

.tagsCellLoader{
    height: 22px;
}

.tagsCellLoader svg{
    /*height: 100%;*/
}


.wrap{
    display: flex;
    align-items: center;
    justify-content: center;
}


.accountsWrap{
    display: flex;
    flex-direction: column;

}

.accountsWrap .account{
    display: flex;
    align-items: center;
    /*margin: 10px 0;*/
    padding: 10px 30px 10px 10px;
    transition: 0.2s;
    cursor: pointer;
}


.accountsWrap .account:hover{
    background: var(--darkAccents-7);
}

.account .accountInfo{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.accountInfo .accountFullName{
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.accountInfo .accountId{
    font-size: 12px;
    color: var(--darkAccents-3);
    margin-top: 5px;
    line-height: 12px;
}



.formInputWrap{
    display: flex;
    position: relative;
    align-items: flex-start;
    /*background: var(--backgroundColor);*/
    transition: 0.1s;
    border: 1px solid transparent;
    border-radius: 5px;
    /*margin-left: -10px;*/
}
.formInputWrap:hover{
    border: 1px solid var(--darkAccents-7);
}

.formInputWrap .dragIndicator{
    position: absolute;
    left: -22px;
    margin-right: 4px;
    margin-top: 4px;
    cursor: move;
    transition: 0.1s;
    display: flex;
    padding: 3px;
    border-radius: 5px;
    opacity: 0;
}

.formInputWrap .dragIndicator:hover{
    background: var(--darkAccents-8);
}

.formInputWrap:hover .dragIndicator{
    opacity: 1;
}

.inputWrap{
    width: 100%;
    display: flex;
    align-items: flex-start;
    transition: 0.1s;
    padding: 8px;
    border-radius: 5px;
    flex-direction: column;
    border: 1px solid transparent;
}

.inputWrap .input{
    border: 0;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 14px;
    background: transparent;
    transition: 0.1s;
    flex: 1;
    min-width: 10px;
}

.inputWrap .input:focus{
    outline: 1px solid var(--darkAccents-4);
    margin-right: 3px;
}


.inputWrap .inputHead{
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    transition: 0.1s;
    cursor: pointer;
}

.inputWrap .inputHead .inputHeadLabel{
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.5px;
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.1s;
    display: flex;
    flex: 1;
}



.inputHead .inputHeadDelete{
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-left: auto;
    color: var(--errorColor);
    width: 16px;
    height: 16px;
}

.inputHead .inputHeadDelete:hover{
    background: var(--darkAccents-5);
}
.inputWrap:hover .inputHead .inputHeadDelete{
    display: flex;
}

.inputHeadLabel .inputHeadLabelInput{
    border: none;
    outline: none;
    background: transparent;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.5px;
    font-weight: 600;
    flex: 1;
    text-transform: uppercase;
}
.inputWrap .inputHead.editable{
    width: 100%;
}
.inputWrap .inputHead.editable:hover{
    background: var(--darkAccents-8);
}

.inputWrap .inputHead .inputHeadIcon{
    display: flex;
    margin-right: 5px;
}


.inputWrap .inputBody{
    display: flex;
    width: 100%;
}

.inputBody .loadingInput{
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 5px;
}



.inputWrap .inputBody .inputActions{
    display: none;
    align-items: stretch;
    justify-content: flex-end;
}

.inputWrap:hover .inputBody .inputActions{
    display: flex;

}

.inputAction{
    display: flex;
    align-items: center;
    transition: 0.1s;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}


.inputAction .inputActionIcon{
    display: flex;
}

.inputAction.success .inputActionIcon{
    color: var(--greenDark);
}


.inputBody .inputAction.warning .inputActionIcon{
    color: var(--warningColor);
}


.inputBody .inputAction.error .inputActionIcon{
    color: var(--errorColor);
}


.inputAction:hover{
    background: var(--darkAccents-8);
}

.inputWrap .inputInfos{
    display: flex;
    align-items: stretch;
}

.inputWrap .inputInfos .inputInfo{
    display: flex;
    align-items: center;
    transition: 0.1s;
    padding: 5px;
    border-radius: 5px;
}

.inputWrap .inputInfos .inputInfo:hover{
    background: var(--darkAccents-8);
}


.inputWrap .inputInfos .inputInfo.invalid{
    color: red;
}


.inputBody .inputInfos .inputInfo .inputInfoIcon{
    display: flex;
}

.formActions{
    margin-top: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}


.wrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
}


.inputBodySocial{
    width: 100%;
    display: flex;
    padding: 5px;
}

.inputBodySocial .socialIcon{
    margin-right: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputBodySocial .socialCard{
    justify-content: center;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.inputBodySocial .socialCard .socialName{
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    max-width: 200px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.inputBodySocial .socialCard .socialPosition{
    font-size: 11px;
    margin-top: 2px;
    line-height: 12px;
    color: var(--darkAccents-3);
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.socialTypeMenu{
    display: flex;
    flex-direction: column;
    margin: 5px 0;
}

.socialTypeMenu .socialTypeMenuItem{
    display: flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    transition: 0.1s;
}
.socialTypeMenu .socialTypeMenuItem:hover{
    background: var(--darkAccents-8);
}

.badgeWrap{
    cursor: pointer;
}


.list{
    width: 100%;
    z-index: 10;
    overflow: auto;
    border-radius: 5;
}


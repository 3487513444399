.wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
}


.wrap .editorWrap{
    width: 100%;
    height: 100%;
}

.wrap .editorWrap{
    max-height: 300px;
    overflow: auto;
    padding: 10px;
    font-size: 14px;
}

.wrap .editorWrap>div{
    padding: 0 !important;
}

.wrap .editorWrap p{
    margin: 0;
}


.wrap .actions{
    display: flex;
    flex-direction: row;
    /*margin-top: 10px;*/
    width: 100%;
    padding: 10px;
    /*background: red;*/
}

.wrap .actions .action{
    padding: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
    margin-right: 5px;
}

.wrap .actions .action:hover{
    background: var(--darkAccents-7);
}

.wrap .actions .action:active{
    transform: scale(0.9);
}

.wrap .actions .submit{
    margin-left: auto;
}

.wrap .actions .submit .submitBtn{
    background: transparent;
    display: flex;
    font-size: 14px;
    padding: 2px 10px;
    min-height: 27px;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background: var(--mainColor);
    border-radius: 20px;
    cursor: pointer;
    color: white;
    transition: 0.2s;
}

.wrap .actions .submit .submitBtn .submitBtnIcon{
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap .actions .submit .submitBtn:hover{
    background: var(--mainColor2);
}

.wrap .actions .submit .submitBtn:active:not(:disabled){
    transform: scale(0.9);
}


.wrap .actions .submit .submitBtn:disabled{
    background: transparent;
    cursor: not-allowed;
    border: 1px solid var(--darkAccents-4);
    color: var(--darkAccents-4);
}

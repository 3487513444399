
.wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.wrap .feedWrap {
    width: 100%;
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 0px;
    grid-template-columns: minmax(0, 480px) minmax(0, 1fr);
    grid-auto-flow: column;
    box-sizing: border-box;
}

.wrap .feedWrap.two{
    grid-template-columns: minmax(0, 480px) minmax(0, 1fr);
}

.wrap .feedWrap.inbox{
    height: 100%;
    align-items: flex-start;
    overflow: auto;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    padding-bottom: 1rem;
}

.wrap .feedWrap.inbox.bigMode{
    grid-template-columns: minmax(0, 1.2fr) minmax(0, 2fr);
}

.trackCard {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    background: var(--darkAccents-6);
    /*border: 1px solid var(--darkAccents-6);*/
    padding: 20px;
    border-radius: 10px;
    /*border: 2px solid black;*/
    background: var(--linkedinColor); /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, var(--linkedinColor), var(--twitterColor)); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top right, var(--linkedinColor), var(--twitterColor));
    background-color:#9999ff;
    background-image:
            radial-gradient(at 73% 18%, hsla(246,66%,67%,1) 0px, transparent 50%),
            radial-gradient(at 13% 96%, hsla(166,64%,44%,1) 0px, transparent 50%),
            radial-gradient(at 0% 46%, hsla(212,100%,47%,1) 0px, transparent 50%),
            radial-gradient(at 65% 10%, hsla(310,74%,65%,1) 0px, transparent 50%),
            radial-gradient(at 66% 70%, hsla(261,55%,60%,1) 0px, transparent 50%);
}

.trackCard.warning{
    background-color: var(--backgroundColor);
    background-image: none;
    border: 2px solid var(--warningColor);
}

.trackCard h4{
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    color: var(--text-base);
}

.trackCard p{
    font-size: 14px;
    margin: 0;
}

.trackCard .trackHead {
    display: flex;
    flex-direction: column;
}

.trackCard .trackHead h3 {
    font-size: 26px;
    font-weight: 700;
    margin: 0;
    color: white;
}

.trackCard.warning .trackHead h3 {
    color: white;
}


.trackCard .trackHead span {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    color: var(--accents-7);
}

.trackCard button{
    background: white !important;
    color: black;
}


.trackCard.warning button{
    background: var(--warningColor) !important;
    color: white;
}

.trackCard .action{
    width: 100%;
}

.emptyFeedWrap .requirements{
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: flex-start;
}

.emptyFeedWrap .trackWrap{
    width: 100%;
    margin-top: 20px;
}

.emptyFeedWrap .requirements .requirement{
    display: flex;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500;
    border-radius: 8px;
    color: var(--darkAccents-3);
    margin-right: 10px;
    align-items: center;
    justify-content: flex-start;
    transition: 0.2s;
}

.emptyFeedWrap .requirements .requirement:last-child{
    margin-right: 0;
}
.emptyFeedWrap .requirements .requirement .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.emptyFeedWrap .requirements .requirement:hover{
    background: var(--darkAccents-3);

    color: var(--text-secondary);
}

.wrap .feedDiv {
    display: flex;
    flex-direction: column;
    flex: 3;
    max-width: 650px;
    box-sizing: border-box;
}

.wrap .side {
    flex: 2;
    width: 100%;
}

.wrap .side.noMaxWidthCompany:first-child {
    max-width: inherit;
}


.wrap .side .sticky {
    position: sticky;
    top: 83px;
    width: 100%;
}

.wrap .side .sticky.lead {
    top: 10px;
}

.wrap .side .sticky.overflow {
    overflow: auto;
    overflow-x: hidden;
    background: transparent;
}

.wrap .side.navbar .sticky {
    top: 100px;
}

.wrap .side .sticky.hideScroll::-webkit-scrollbar {
    display: none;
}


.wrap .side.noMargin {
    margin-right: 0;
    margin-left: 0;
}


.loadMoreWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20px;
    /*background: red;*/
}

.emptyFeed {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    margin-bottom: 20px;
    justify-content: center;
    text-align: center;
    align-items: center;
    background: var(--backgroundColor);
    border-radius: 7px;
    border: 1px solid var(--darkAccents-7);
}

.isDark {
    filter: invert(100%);
    -webkit-filter: invert(100%);
}

.emptyFeed .emptyFeedImage {
    height: 250px;
    width: 300px;
}

.emptyFeed .emptyFeedImage img {
    object-fit: contain;
}

.emptyFeed .emptyFeedText {
    font-size: 22px;
    font-weight: 600;
    color: var(--text-base);
}

.emptyFeed .emptyFeedTextSmall {
    text-align: center;
    font-size: 16px;
    margin-top: 5px;
    color: var(--darkAccents-3);
}

.emptyFeed .emptyFeedActions {
    margin-top: 20px;
}

.loadingFeed {
    display: flex;
    flex-direction: column;
    padding: 40px;
    justify-content: center;
    align-items: center;
    background: var(--backgroundColor);
    border-radius: 7px;
    border: 1px solid var(--darkAccents-7);
}


.emptyLead{
    display: flex;
    flex-direction: column;
    /*border: 1px solid var(--darkAccents-7);*/
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    padding: 20px 20px 0;
    background: var(--violetLight);
    color: white;
    background-image: url("https://images.unsplash.com/photo-1545431619-92b140e1d334?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDN8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=800&q=60");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.emptyLead .emptyFeedText{
    font-size: 30px;
    font-weight: 700;
}

.emptyLead .emptyFeedTextSmall{
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    font-weight: 500;
}


.emptyLead .images{
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyLead .images .plus{
    padding: 15px;
    display: flex;
}


.emptyLead .emptyFeedFullImage{
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyLead .emptyFeedFullImage img{
    object-fit: contain;
    border: 2px solid var(--darkAccents-7);
    border-radius: 20px 20px 0 0;
    border-bottom: none;
    padding: 5px 5px 0;
    overflow: hidden;
    background: var(--backgroundColor);
}


.lastSyncWrapper{
    margin-bottom: 15px;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    padding: 10px;
    border-radius: 7px;
}

.lastSyncWrapper.linkedin{
    background: var(--linkedinColor);
    color: white;
}

.lastSyncWrapper.twitter{
    background: var(--twitterColor);
    color: white;
}

.lastSyncWrapper .lastSync{
    display: flex;
    width: 100%;
    align-items: center;
    /*flex-direction: column;*/
}

.lastSyncWrapper .lastSyncLogo{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}



.lastSyncWrapper .lastSyncTextWrap{
    display: flex;
    flex-direction: column;
}
.lastSyncWrapper .lastSyncText{
    font-size: 15px;
    line-height: 15px;
    font-weight: 500;
}


.lastSyncWrapper .lastSyncTextSmall{
    font-size: 13px;
    line-height: 13px;
    margin-top: 5px;
    font-weight: 400;
}


.showMore{
    /*margin-top: -30px;*/
    background: var(--darkAccents-9);
    color: var(--mainColor);
    border:1px solid var(--darkAccents-7);
    /*border-top: none;*/
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    align-items: flex-start;
    justify-content: flex-start;
    transition: 0.2s;
    padding: 15px 10px 15px 10px;
    margin-bottom: 15px;
    position: relative;

}
.showMore:hover{
    background: var(--darkAccents-8);
}

.showMore .label{
    z-index: 1;
    font-weight: 400;
    color: var(--text-base);
    line-height: 14px;
    transition: 0.2s;
    font-size: 14px;
}

.showMore .label b{
    font-weight: 600;

}

.showMore:hover .label{
    /*color: var(--mainColor);*/
}

.emptyFeedWrap{
    display: flex;
    align-items: flex-start;
    /* justify-content: center; */
    flex-direction: column;
    background: var(--backgroundColor);
    padding: 30px;
    border-radius: 10px;
    border: 1px solid var(--darkAccents-7);
    justify-content: center;
    margin-bottom: 20px;
}

.emptyFeedWrap .title{
    font-size: 22px;
    font-weight: 600;
}
.emptyFeedWrap .subtitle{
    color: var(--darkAccents-4);
}

.emptyFeedWrap .checks{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 10px;
    margin-top: 20px;
}


.emptyFeedWrap .checks .startCard{
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 20px;
    border: 1px solid var(--darkAccents-6);
    cursor: pointer;
    transition: 0.2s;
}

.emptyFeedWrap .checks .startCard:hover{
    background: var(--darkAccents-8);
}

.emptyFeedWrap .checks .startCard .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.emptyFeedWrap .checks .startCard .icon{
    background: var(--mainColor);
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.emptyFeedWrap .checks .startCard .linkIcon{
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--darkAccents-4);
}

.emptyFeedWrap .checks .startCard .name{
    font-size: 14px;
    font-weight: 700;
    color: var(--mainColor);
}

.emptyFeedWrap .checks .startCard .description{
    font-size: 12px;
    color: var(--darkAccents-4);
}


.feedHeader{
    display: flex;
    width: 100%;
    margin-bottom: 7px;
    justify-content: space-between;
}

.feedHeader .feedHeaderSide{
    display: flex;
    align-items: center;
}

.feedHeader .feedHeaderSide .lastSyncDate{
    font-size: 13px;
    color: var(--darkAccents-4);
}

.feedHeader .feedHeaderFilterSocials{
    display: flex;
    align-items: center;
    justify-content: center;
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial{
    display: flex;
    align-items: center;
    padding: 2px 5px;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    line-height: 13px;
    min-height: 24px;
    transition: 0.2s;
    border-radius: 5px;
    margin-right: 5px;
    position: relative;
    box-sizing: border-box;
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial .line{
    width: 90%;
    position: absolute;
    height: 1px;
    background: var(--darkAccents-4);
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial.selected .line{
    display: none;
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial:last-child{
    margin-right: 0;
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial:hover{
    background: var(--darkAccents-8);
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial .feedHeaderFilterSocialIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    color: var(--darkAccents-4);
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial.selected .feedHeaderFilterSocialIcon{
    color: var(--text-base);
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial.selected .feedHeaderFilterSocialText{
    color: var(--text-base);
}

.feedHeader .feedHeaderFilterSocials .feedHeaderFilterSocial .feedHeaderFilterSocialText{
    color: var(--darkAccents-4);
}

.feedHeader .feedHeaderSide .feedHeaderOrderBy{
    padding: 2px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 5px;
}

.feedHeader .feedHeaderSide .feedHeaderOrderBy:hover{
    background: var(--darkAccents-8);
}

.feedHeader .feedHeaderSide .feedHeaderOrderByIcon{
    display: flex;
    align-items: center;
    justify-items: center;
    transition: 0.2s;
}


.feedHeader .feedHeaderSide .feedHeaderOrderBy.asc .feedHeaderOrderByIcon{
    transform: rotate(180deg) rotateY(180deg);
}


.lastSyncTooltip{
    display: flex;
    flex-direction: column;
}

.lastSyncTooltip .tooltipContentLine{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: var(--darkAccents-4);
}

.lastSyncTooltip .tooltipContentLine b{
    font-weight: 600;
    color: var(--text-base);
    margin-right: 8px;
}

/*.lastSyncTooltip .tooltipContentLine .bold{*/
/*    font-weight: 500;*/
/*    color: var(--text-base);*/
/*}*/


.filters{
    margin-bottom: 10px;
}

.announcement{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    border-radius: 8px;
    background: var(--darkAccents-9);
    margin-bottom: 10px;
    border: 1px solid var(--darkAccents-6);
}

.announcement .text{
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: var(--text-base);
}

.announcement .link{
    color: var(--mainColor);
    font-weight: 600;
    font-size: 14px;
}

.wrap{
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 5px 0;
}

.wrap .inputs{
    display: flex;
    flex-direction: column;
}

.inputs .inputRow{
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
    /*margin-bottom: 10px;*/
    padding: 7px;
    /*border-radius: 4px;*/
    cursor: pointer;
}

.inputs .inputRow:hover{
    background: var(--darkAccents-8);
}

.inputRow .inputIcon{
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}
.inputRow .inputName{
    display: flex;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    display: -webkit-box;
    text-transform: capitalize;
    margin-right: 10px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.inputRow .inputSwitch{
    display: flex;
    margin-top: -4px;
}


.wrap .titleWrap{
    padding: 7px;
    border-bottom: 1px solid var(--darkAccents-8);
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: var(--darkAccents-4);
}

.wrapInput{
    flex: 1;
}

.wrapInput .buttonTrigger{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 5px;
    border: 1px solid var(--darkAccents-4);
    color: var(--text-base);
    cursor: pointer;
    transition: 0.2s;
    border-radius: 5px;
    font-size: 14px;
    line-height: 14px;
}

.wrapInput .buttonTrigger:hover{
    background: var(--backgroundColor);
}

.wrap{
    display: flex;
    align-items: center;
    overflow: hidden;
    border-bottom-left-radius: 10%;
    border-top-left-radius: 10%;
    opacity: 0;
    animation: entryAnimation 2s cubic-bezier(.25,.1,.25,1) 0s 1 normal forwards;

}


.favicon{
    z-index: 1;
    display: flex;
}


.text{
    display: flex;
    margin-left: -250px;
    animation: entryAnimationText 1.5s cubic-bezier(.25,.1,.25,1) 1s 1 normal forwards;
}


@keyframes entryAnimation {
    0%{
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}


@keyframes entryAnimationText {
    0%{
        margin-left: -250px;
    }
    50% {
        margin-left: 10px;
    }
    100% {
        margin-left: 10px;
    }
}

.wrapper{
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    background: var(--backgroundColor);
    z-index: 1;
    border: 1px solid var(--darkAccents-7);
    border-radius: 10px;
    margin-bottom: 15px;
    box-shadow: 0 8px 30px rgba(0,0,0,.03);
}

.wrapper.noMaxWidth{
    max-width: inherit;
}

.post{
    display: flex;
    flex-direction: column;
}

.header{
    display: flex;
    padding: 15px;
    border-bottom: 1px solid var(--darkAccents-7);
}

.small .header{
    padding: 10px;
}

.header.repost{
    padding: 6px 10px;
    background: var(--darkAccents-8);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.header .actions{
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header .action{
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;
    cursor: pointer;
    width: 35px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 35px;

}

.header .action svg path{
    transition: 0.2s;
}

.header .action:hover{
    background: var(--darkAccents-8);
}


.lead{
    display: flex;
    align-items: center;
}


.lead .profile{
    display: flex;
    flex-direction: column;

}


.lead .avatar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.lead .profile .profileTop{
    display: flex;
    align-items: center;
}

.lead .profile .profileTop .socialLogo{
    display: flex;
    margin-left: 5px;
}


.lead .profile .name{
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    max-width: 240px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lead .profile .placeholder{
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    color: var(--darkAccents-4);
}

.small .lead .profile .name{
    font-size: 13px;
    display: inline-block;
    overflow: hidden;
    max-width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.lead .profile .separator{
    background: var(--darkAccents-4);
    margin: 0 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}

.lead .profile .postedAt{
    font-size: 14px;
    color: var(--darkAccents-4);
}
.lead .profile .repostText{
    font-size: 14px;
    font-weight: 500;
    color: var(--text-base);
}

.small .lead .profile .postedAt{
    font-size: 11px;
}

.lead .profile .handle{
    font-size: 13px;
    line-height: 16px;
    color: var(--darkAccents-4);
    font-weight: 400;
    display: flex;
    align-items: center;
}

.lead .profile .handle .description{
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    max-width: 280px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.small .lead .profile .handle{
    font-size: 11px;
    line-height: 11px;
}

.lead .profile .handle .socialLogo{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin-right: 3px;
    height: 16px;
    width: 16px;
    background: #0072b1;
}

.small .profile .handle .socialLogo{
    min-width: 12px;
    min-height: 12px;
    width: 12px;
    height: 12px;
}

.lead .profile .handle .socialLogo.twitter{
    background: #00aced;
    padding: 2px;
}

.body{
    display: flex;
    flex-direction: column;
    padding: 10px 10px;
    font-size: 14px;
}

.small .body{
   font-size: 12px;
}

.body .children{
    border: 1px solid var(--darkAccents-8);
    border-radius: 7px;
    /*padding: 10px;*/
    margin-top: 10px;
}

.body .children .header{
    padding: 10px;
}

.body .children .profile .profileTop .name{
    font-size: 14px;
}
.body .children .profile .postedAt{
    font-size: 12px;
    line-height: 12px;
}

.medias{
    box-sizing: border-box;
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    margin: 10px;
    border-radius: 10px;
    grid-gap: 1px;
    background-color: var(--darkAccents-7);
    /*background: red;*/
}

.medias .media{
    display: flex;
    overflow: hidden;
}


.medias .media.video{
    align-items: center;
    justify-content: center;
}
.body p{
    margin: 0;
}
.footer{
    padding: 10px;
    border-top: 1px solid var(--darkAccents-7);
    display: flex;
}

.small .footer{
    padding: 5px 10px;
}

.footer .actions{
    display: flex;
    flex: 1;
}

.footer .actions .action{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    flex: 1;
    transition: 0.2s;
    border-radius: 5px;
}

.footer .actions .action .actionCount{
    font-size: 14px;
    font-weight: 600;
    margin-right: 5px;
}

.small .footer .actions .action .actionCount{
    font-size: 12px;
}

.footer .actions .action:hover{
    background: var(--darkAccents-7);
}


.footer .actions.disabled .action:hover{

}

.footer .actions.disabled .action:hover svg path{
    fill: black;
}


.footer .actions.disabled .action:hover{
    cursor: default;
    background: transparent;
}

.footer .actions .action .actionIcon{
    display: flex;
}

.footer .actions .action .actionLabel{
    font-size: 14px;
    font-weight: 600;
    margin-left: 5px;

}

.small .footer .actions .action .actionLabel{
    font-size: 12px;
}

.responseWrap{
    flex-direction: column;
    display: flex;
    padding: 5px 10px 15px 10px;
}

.responseWrap.static{
    border-top: 1px solid var(--darkAccents-7);
    padding: 10px 10px 10px 10px;
    background: var(--darkAccents-9);
}

.responseWrap .successCard{
    padding: 4px 10px;
    border-radius: 7px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: var(--greenDark);
    background: var(--greenUltraLight);
}

.responseWrap .connectCard{
    padding: 5px 10px;
    border-radius: 5px;
    background: #1DA1F3;
    border: 2px solid #1DA1F3;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    transition: 0.2s;
}

.responseWrap .connectCard.linkedin{
    background: #0077B5;
    border: 2px solid #0077B5;
}

.connectCard .action{
    fill: white;
    transition: 0.2s;
    color: var(--text-secondary);
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.responseWrap .connectCard:hover{
    border: 2px solid #1DA1F3;
    background: transparent;
}


.responseWrap .connectCard.linkedin:hover{
    background: transparent;
    border: 2px solid #0077B5;
}

.responseWrap .connectCard .text{
    font-size: 14px;
    font-weight: 500;
    color: white;
    flex: 1;
}

.responseWrap .connectCard:hover .text{
    color: #1DA1F3;
}

.responseWrap .connectCard:hover .action{
    color: #1DA1F3;
    fill: #1DA1F3;
}

.responseWrap .connectCard.linkedin:hover .text{
    color: #0077B5;
}

.responseWrap .connectCard.linkedin:hover .action{
    color: #0077B5;
    fill: #0077B5;
}


.responseWrap .top{
    display: flex;
    align-items: flex-start;
}

.responseWrap .bottom{
    /*display: flex;*/
    align-items: center;
    font-size: 12px;
    color: var(--darkAccents-4);
    margin-top: 5px;
}

.responseWrap .response{
    display: flex;
    position: relative;
    /*background: red;*/
    flex: 1;
}


.responseWrap.static .response{
    flex-direction: column;
}

.responseWrap .response .renderAuthor{
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 3px;
    font-weight: 600;
}
.responseWrap .response .renderAuthor .date{
    font-size: 12px;
    font-weight: 400;
    color: var(--darkAccents-4);
}

.responseWrap.small .response .renderAuthor{
    font-size: 13px;
    line-height: 12px;
}
.responseWrap .response .renderText{
    font-size: 14px;
    word-break: keep-all;
    /*border: 1px solid var(--darkAccents-7);*/
    border-radius: 50px;
}

.responseWrap.small .response .renderText{
    font-size: 13px;
}

.responseWrap .responseAvatar{
    margin-right: 5px;
    cursor: pointer;
    border-radius: 50%;
    transition: 0.2s;
    /*border: 2px solid transparent;*/
    box-shadow:0px 0px 0px 2px transparent;
}

.responseWrap .responseAvatar:hover{
    background: var(--darkAccents-5);
    /*border: 2px solid var(--mainColor);*/
    box-shadow:0px 0px 0px 2px var(--mainColor);
}

.responseWrap .responseAvatar.static{
    box-shadow: none;
    cursor: default;
}

.responseWrap .responseAvatar span{
    cursor: pointer;
}

.responseWrap .responseAvatar.static span{
    cursor: default;
}

.responseWrap .response input{
    flex: 1;
    border: 1px solid var(--darkAccents-7);
    border-radius: 30px;
    font-size: 14px;
    padding: 10px 15px;
}

.responseWrap .response .textArea {
    flex: 1;
    border: 1px solid var(--darkAccents-7);
    border-radius: 30px;
    font-size: 14px;
    padding: 10px 40px 10px 15px;
    margin: 0;
    resize: none;
}

.responseWrap .responseHoverActions{
    position: absolute;
    right: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.responseHoverActions .responseHoverAction{
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 20px;
    transition: 0.2s;
    color: var(--darkAccents-4);
    justify-content: center;
}

.responseHoverActions .responseHoverAction:hover{
    color: var(--text-base);
    background: var(--darkAccents-7);
}

.responseWrap .submit{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
}



.dropdownButton:hover{
    background: var(--darkAccents-7);
}


.lead.repost{
    /*display: none;*/
    align-items: center;
    justify-content: center;
}

.linkedinRenderWrap{
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
}


.linkedinRenderWrap .linkedinRenderText{
    display: -webkit-box;
    font-family: var(--system-ui);
    /*max-width: 200px;*/
    overflow: hidden;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.linkedinRenderWrap.seeAll .linkedinRenderText{
    -webkit-line-clamp: unset;
}


.linkedinRenderWrap .more{
    font-weight: 500;
    text-align: right;
    padding-left: 5px;
    cursor: pointer;
    color: var(--darkAccents-4);
    margin-top: 5px;
    transition: 0.2s;
    position: absolute;
    bottom: 0;
    right: 0;
    background: var(--backgroundColor);
}

.linkedinRenderWrap .more:hover{
    color: var(--mainColor);
    text-decoration: underline;
}


.linkedinRenderWrap.seeAll .more{
    position: inherit;
}

.header.company{
    padding: 7px 10px;
}

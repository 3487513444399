.wrap{
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 5px 0;
}

.wrap .inputs{
    display: flex;
    overflow: auto;
    max-height: 400px;
    flex-direction: column;
}

.inputs .inputRow{
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    /*margin-bottom: 10px;*/
    /*padding: 7px;*/
    /*border-radius: 4px;*/
    cursor: pointer;
}

.inputs .inputRow:hover{
    background: var(--darkAccents-8);
}

.inputRow .inputName{
    display: flex;
    box-sizing: border-box;
    flex: 1;
    padding: 8px 15px 8px 7px;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    display: -webkit-box;
    text-transform: capitalize;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.inputRow .inputSwitch{
    display: flex;
    align-items: center;
    margin-top: -4px;
    padding-right: 8px;
}


.wrap .titleWrap{
    text-align: center;
    padding: 7px;
    border-bottom: 1px solid var(--darkAccents-8);
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    color: var(--darkAccents-4);
}

.wrapInput{
    flex: 1;
}

.wrapInput .buttonTrigger{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 5px;
    color: var(--darkAccents-4);
    border: 1px solid transparent;
    cursor: pointer;
    transition: 0.2s;
    border-radius: 5px;
    font-size: 14px;
    line-height: 14px;
}

.wrapInput .buttonTrigger:hover{
    background: var(--darkAccents-8);
}


.emojiPickable{
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-radius: 8px;
}

.emojiPickable:hover{
    background-color: var(--darkAccents-8);
}

.emoji{

}


.leadValue{
    font-size: 14px;
    font-weight: 700;
    padding: 3px 7px;
    border-radius: 20px;
    background: var(--greenUltraLight);
    color: var(--greenDark);
    line-height: 14px;
    cursor: pointer;
    transition: 0.2s;
}

.leadValue:hover{
    transform: scale(1.1);
    background-color: var(--darkAccents-7);
}

.leadValueWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}


.contractValueForm{
    display: flex;
    /*padding: 10px;*/
    color: var(--text-base);
}


.popoverWrap{
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: flex-end;*/
    /*width: 100%;*/
    margin-left: 5px;
}



.iconWrap {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative;
    color: var(--darkAccents-4);
    /*border: 1px solid var(--newInfoColorLight);*/
    /*color: var(--newInfoColor);*/
    /*background: var(--newInfoColorUltraLight);*/
}

.iconWrap.move>svg{
    margin-left: -4px;
    margin-bottom: -4px;}


.iconWrap.fill svg path {
    fill: var(--text-base)
}

.warning .iconWrap.fill svg path{
    fill: var(--newWarningColor);
}

.danger .iconWrap.fill svg path{
    fill: var(--newErrorColor);
}

.iconWrap .socialIcon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 0;
    right: 0;
    min-height: 16px;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    /*border-radius: 50%;*/
}

.iconWrap .socialIcon.linkedin {
    background: var(--linkedinColor);
    color: white;
}

.iconWrap .socialIcon.twitter {
    background: var(--twitterColor);
    color: white;
}

.iconWrap .socialIcon.email {
    background: var(--newInfoColor);
    color: white;
}

.iconWrap svg path {
    /*fill: var(--newInfoColor);*/
    /*stroke: var(--newInfoColor);*/
}

.iconWrap.grey {
    color: var(--darkAccents-4);
}

.iconWrap.grey svg path {
    fill: var(--darkAccents-4);
    stroke: var(--darkAccents-4);
}

svg.noStroke path {
    stroke: none !important;
}

.active .iconWrap {
    color: var(--text-base);
    /*background: var(--backgroundColorLight);*/
    border-radius: 50%;
}


.renderContent {
    font-size: 14px;
    white-space:pre-wrap;
}

.renderContent a {
    background: var(--newInfoColorUltraLight);
    color: var(--newInfoColor);
    padding: 1px 3px;
    border-radius: 2px;
    font-weight: 500;
    cursor: text;
}

.inputWrap {
    box-sizing: border-box;
    width: 100%;
    background: var(--backgroundColor);
    border: 1px solid var(--darkAccents-7);
    box-shadow: 0 4px 7px rgba(0, 0, 0, .05);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.editNote {
    padding: 10px;
    width: 100%;
    position: relative;
    border-bottom: 1px solid var(--darkAccents-6);
    background: var(--darkAccents-9);
    display: flex;
    align-items: center;
}

.editNote .renderContent {
    font-size: 12px;
}

.editNote .renderContent a {
    background: var(--newInfoColorUltraLight);
    color: var(--newInfoColor);
    padding: 1px 3px;
    border-radius: 2px;
    font-weight: 500;
    cursor: text;
}

.editNote .editActions {
    position: absolute;
    right: 0px;
    top: 0px;
}

.editNote .editActions .action {
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--darkAccents-4);
    transition: 0.2s;
}

.editNote .editActions .action:hover {
    background: var(--darkAccents-7);
    border-radius: 5px;
    color: var(--text-base);
}

.editNote .editActions .action:active {
    transform: scale(0.9);
}

.inputWrap textarea {
    font-size: 14px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: none;
    padding: 7px;
    resize: none;
    margin: 0;
}


.inputWrap .actions {
    display: flex;
    padding: 5px;
    width: 100%;
}

.inputWrap .actions .action {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s;
}

.inputWrap .actions .action:hover {
    background: var(--darkAccents-8);
    border-radius: 5px;
}


.inputWrap .actions .submit {
    margin-left: auto;
}

.inputWrap .actions .submit .submitBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    height: 27px;
    padding: 3px 8px;
    font-size: 14px;
    line-height: 14px;
    background: var(--mainColor);
    border: 1px solid transparent;
    cursor: pointer;
    color: white;
}

.inputWrap .actions .submit .submitBtn:disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 3px 8px;
    background: none;
    border: 1px solid transparent;
    color: var(--darkAccents-7);
}

.inputWrap .actions .submit .submitBtnIcon {
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--darkAccents-7);
}

.filters {
    display: flex;
    width: 100%;
    z-index: 10;
    padding: 6px 8px;
    border-radius: 10px;
    overflow: hidden;
    background: var(--mainColor2);
    color: white;
}

.filters.popover{
    flex-direction: column;
    padding: 0px;
    min-width: 140px;
}

.filterTitle {
    margin-right: auto;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
}

.filters.popover .filterTitle{
    padding: 3px 10px;
    background: var(--darkAccents-9);
    border-bottom: 1px solid var(--darkAccents-7);
    width: 100%;
    color: var(--text-base);
}



.filters .filtersGroup {
    display: flex;
    border-radius: 5px;
    /*border-radius: 30px;*/
    overflow: hidden;
}

.filters.popover .filtersGroup{
    flex-direction: column;
    background: var(--backgroundColor);
    color: var(--text-base);
    border-radius: 0;
}

.filters .filter {
    font-size: 14px;
    padding: 2px 10px;
    border-radius: 8px;
    transition: 0.1s;
    cursor: pointer;
    /*border-radius: 30px;*/
}

/*.filters .filter:first-child{*/
/*    border-top-left-radius: 5px;*/
/*    border-bottom-left-radius: 5px;*/
/*}*/

.filters.popover .filter{
    border-radius: 0;

}


.filters .filter:hover {
    background: var(--newInfoColor);
}

.filters .filter.active {
    background: var(--newInfoColorLight);
    color: black;
}

.filters.popover .filter.active {
    background: var(--newInfoColorLight);
    color: black;
}

.filterAction{
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
}

.mail {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--darkAccents-6);
    border-radius: 5px;
    margin-top: 10px;
}

.mail .to {
    padding: 5px;
    border-bottom: 1px solid var(--darkAccents-7);
}

.mail .from {
    padding: 5px;
    border-bottom: 1px solid var(--darkAccents-7);
}

.mail .subject {
    padding: 5px;
    font-weight: 600;
    border-bottom: 1px solid var(--darkAccents-7);
}

.mail .body {
    padding: 5px;
}


.message{
    display: flex;
    flex-direction: column;
}

.message p{
    margin: 1em 0;
}


 .mail .email {
    font-size: 14px;
    color: var(--darkAccents-4);
    margin-left: 5px;
}



.editor{
    background: transparent;
    border: none;
}

.editor>div{
    background: transparent;
    min-height: inherit;
    padding: 0;
}


.editor>div>div{
    padding: 0 !important;
}


.smallLinesWrap{
    display: flex;
    border-radius: 8px;
    flex-direction: column;
}

.smallLinesWrap .smallLinesWrapHead{
    border-radius: 8px;
    display: flex;
    z-index: 20;
    cursor: pointer;
    align-items: center;
    /*justify-content: space-between;*/
    padding: 5px;
    background: var(--backgroundColor);
    position: sticky;
    top: 0;
    border: 1px solid var(--darkAccents-7);
    transition: 0.1s;
}

.smallLinesWrap .smallLinesWrapHead:hover{
    background: var(--darkAccents-7);
    border: 1px solid var(--darkAccents-6);
}

.smallLinesWrapHead .smallLinesWrapTitle{
    font-size: 14px;
    font-weight: 400;
    color: var(--darkAccents-4);
}

.smallLinesWrapHead .smallLinesWrapTitle b{
    color: var(--text-base);
}

.smallLinesWrapHead .smallLinesChevron{
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.smallLinesWrap .smallLinesWrapLines{
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.wrap{
    width: 100%;
    margin: 0 auto;
    padding: 0;
    /*background: red;*/
}

.loadingWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
}



.button{
    height: 36px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    font-size: 14px;
    padding: 0 5px 0 10px;
    transition: 0.2s;
    background: var(--backgroundColor);
}

.sm .button{
    font-size: 13px;
    height: 31.5px;
}

.border .button{
    border: 1px solid var(--darkAccents-7);
}


.border .button:hover{
    border: 1px solid var(--darkAccents-5)
}


.button:hover{
    background-color: var(--darkAccents-8);
}

.button .chevron{
    color: var(--darkAccents-4);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    transition: 0.2s;
}

.button .chevron.open{
    transform: rotate(180deg);
}

.button:hover .chevron{
    color: var(--text-base);
}

.button.disabled{
    opacity: 0.5;
    cursor: not-allowed;
    border: 1px solid var(--darkAccents-7);
}

.items{
    display: flex;
    flex-direction: column;
    max-height: 300px;
    border-radius: 5px;
    overflow: auto;
}


.items .item{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 7px 30px 7px 10px;
    font-size: 13px;
    border-bottom: 1px solid var(--darkAccents-9);
    transition: 0.2s;
    color: var(--darkAccents-4);
}

.items .item:first-child{
    border-top-left-radius: 14px;
    border-top-right-radius:14px;
}

.items .item:last-child{
    border-bottom: 0;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius:14px;
}

.items .item:hover{
    background-color: var(--darkAccents-7);
}

.items .item.active{
    color: var(--text-base);
}


.items .item .check{
    position: absolute;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mainColor);
}


.label{
    font-size: 14px;
    margin-bottom: 5px;
    color: var(--text-base);
    font-weight: 500;
}


.avatar{
    margin-right: 5px;
}

.wrap{
    display: flex;
    flex-direction: column;
}

.helpText{
    font-size: 12px;
    margin-top: 5px;
    margin-left: 2px;
}

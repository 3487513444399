
.wrap{
    width: 100%;
    display: flex;
    position: relative;
    /*max-height: 400px;*/
    /*overflow-x: auto;*/
}


.textArea {
    width: 100%;
    border: 1px solid var(--darkAccents-7);
    background: var(--backgroundColor);
    box-shadow: none;
    box-sizing: border-box;
    flex: 1 1;
    border-radius: 8px;
    font-size: 13px;
    padding: 10px 40px 10px 15px;
    resize: none;
    max-height: 400px;
    overflow-x: auto;
}

.small .textArea{
    padding: 5px 20px 5px 10px;
    max-height: 200px;
}

.wrap.rounded .textArea{
    border-radius: 30px;
}

.small .reponseCountWords{
    font-size: 10px;
}
.reponseCountWords{
    position: absolute;
    left: 12px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    color: var(--darkAccents-4);
    bottom: 7px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.responseHoverActions{
    position: absolute;
    right: 5px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.small .responseHoverActions .responseHoverAction{
    padding: 7px;

}

.responseHoverActions .responseHoverAction{
    padding: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 20px;
    transition: 0.2s;
    color: var(--darkAccents-4);
    justify-content: center;
    position: relative;
}

.responseHoverActions .responseHoverAction:hover{
    color: var(--text-base);
    background: var(--darkAccents-7);
}

.wrap {
    position: relative;
    display: flex;
    background: var(--darkAccents-8);
    border-radius: 50%;
    overflow: hidden;
}

.wrap.squared{
    border-radius: 5px;
}

.wrap.breakcold{
    background: var(--mainColor);
    padding: 6px;
    align-items: center;
    justify-content: center;
}

.img {
    object-fit: cover;
    z-index: 2;
}



.initials {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 600;
    color: var(--text-base);
}

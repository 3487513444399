.layout{
    position: absolute; /* postulat de départ */
    top: 50%; left: 50%; /* à 50%/50% du parent référent */
    transform: translate(-50%, -50%); /* décalage de 50% de sa propre taille */
    height: 100%;
    border-radius: 10px;
    width: 100%;
    z-index: 99;
    display: flex;
}

.moreButton{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
    transition: 0.2s;
    color: var(--darkAccents-4);
    border-radius: 5px;
}
.moreButton:hover{
    background: var(--darkAccents-6);
}

.backDrop{
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    border-radius: 10px;
    z-index: -1;
}

.wrapper{
    padding: 50px 30px 20px;
    flex: 1;
    background-color: var(--darkAccents-8);
    border-radius: 10px;
    box-sizing: border-box;
}

.leadMainInfo{
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    border-radius: 5px;
}

.leadActionsWrap{
    display: flex;
    flex-direction: column;
}

.leadActionsWrap .leadQuicksActions{
    display: flex;
    margin-top: 13px;
}

.leadActionsWrap .leadQuicksActions .action{
    padding: 10px;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    min-width: 35px;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-secondary);
    background: var(--mainColor);
}

.InputName{
    border: none;
    background: transparent;
    font-size: 34px;
    font-weight: 600;
    max-width: 300px;
}

.InputName:hover{
    background: var(--darkAccents-9);
}

.leadMainInfo .leadAvatar{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.leadMainInfo .leadName{
    display: flex;
    font-size: 16px;
    font-weight: 600;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.leadMainInfo .leadName.twitter{
    color: var(--darkAccents-4);
    font-weight: 500;
}
.leadMainInfo .leadNameWrap{
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    /*justify-content: center;*/
}

.leadMainInfo .company .companyName{
    font-size: 16px;
    color: var(--darkAccents-4);
}

.leadMainInfo .company .companyRole{
    font-size: 14px;
    color: var(--darkAccents-3);
}

.leadInfo{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 5px;
    background-color: var(--backgroundColor);
    border: 1px solid var(--darkAccents-7);
}

.leadInfoLine{
    /*padding: 0 10px;*/
    display: flex;
    /*justify-content: space-between;*/
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
}

.leadInfoLine:last-child {
    margin-bottom: 0;
}

.leadInfoLine .infoLineHead{
    display: flex;
}

.leadInfoLine .infoLineHead .label{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--darkAccents-3);
}

.leadInfoLine .icon{
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.leadInfoLine .icon svg{
    color: var(--darkAccents-4);
}

.leadInfoLine .info{
    width: 100%;
}

.customModal{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 210;
    display: flex;
    align-items: center;
    justify-content: center;
    /*background-color: red;*/
}

.customModal .customModalContent{
    background-color: var(--backgroundColor);
    /*border: 1px solid var(--darkAccents-7);*/
    width: 100%;
    /*height: 100%;*/
    overflow: hidden;
    border-radius: 14px;
    /*max-height: 500px;*/
    max-width: 800px;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    position: relative;
}

.customModal .customModalHeader{
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;
    position: relative;
}

.customModal .customModalHeader h2{
    font-size: 20px;
    font-weight: 400;
    margin: 0;
}
.customModal .customModalClose{
    padding: 10px;
    position: absolute;
    right: 10px;
    cursor: pointer;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    top: 5px;
    transition: 0.3s;
    z-index: 10;
    color: var(--darkAccents-5);
}

.customModal .customModalClose:hover{
    color: var(--text-base);
}

.customModal .contentWrap{
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

.stepWrapper{
    padding: 20px 20px 20px;

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    text-align: center;
}

.optionRow{
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.optionRow .optionLabel{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.optionRow .optionLabel span{
    margin-right: 5px;
}

.optionRow .optionSwitch{
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepWrapper h5{
    font-size: 18px;
    margin: 0;
    color: var(--darkAccents-3);
    margin-bottom: 20px;
}

.stepWrapper h3{
    margin-bottom: 30px;
}

.stepWrapper .actions{
    display: flex;
    width: 100%;
    margin-top: 40px;
    justify-content: center;
    /*justify-content: space-between;*/
}

.stepWrapper .actions.hide{
    display: none;
}

.customModalFooter{
    box-sizing: border-box;
    display: flex;
    padding: 10px;
    /*justify-content: space-between;*/
    width: 100%;
    background-color: var(--darkAccents-8);
    border-top: 1px solid var(--darkAccents-6);
}

.pricePerLead{
    margin-right: 10px;
    /*flex: 1;*/
    color: var(--darkAccents-3);
    display: flex;
    align-items: center;
}
.totalCost{
    /*flex: 1;*/
    margin-right: 10px;
    color: var(--darkAccents-3);
    display: flex;
    align-items: center;
    justify-content: center;
}

.timeToImport{
    /*flex: 1;*/
    margin-right: 10px;
    color: var(--darkAccents-3);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.tagWrap{
    display: flex;
    align-items: center;
}

.customModal .backDrop{
    background-color: rgba(0,0,0,0.8);
}

.confirmation{
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
}


.linkedinButton{
    color: var(--text-base) !important;
    background-color: #0a66c2 !important;
}
.linkedinButton:hover{
    color: #0a66c2 !important;
    background-color: transparent !important;
}

.wrap{
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.wrap .banner{
    min-height: 200px;
    max-height: 200px;
    width: 100%;
    /*background: var(--mainColor);*/
    background: var(--darkAccents-9);
    border-bottom: 1px solid var(--darkAccents-6);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.banner .contentHolder{
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    /*max-width: var(--maxWidthPlusPlus);*/
}

.wrap .nav{
    position: sticky;
    top: 0;
    justify-content: center;
    background-color: var(--backgroundColor);
    z-index: 10;
    height: 42px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--darkAccents-7);
}

.wrap .nav .right{
    justify-content: flex-end;
    display: flex;
}

.wrap .row{
    max-height: 100%;
    overflow: hidden;
    width: 100%;
    flex: 1;
    display: flex;
}


.wrap .item:hover .valueHover{
    display: block;
}
.wrap .item:hover .value{
    display: none;
}

.wrap .nav .maxWidthHolder{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
    /*max-width: var(--maxWidthPlusPlus);*/
}

.wrap .nav .center{
    height: 48px;
    overflow: hidden;
}

.wrap .nav .left{
    height: 48px;
    padding-top: 0;
}

.wrap .left{
    padding-top: 15px;
    padding-bottom: 15px;
    max-width: 320px;
    min-width: 320px;
    flex: 1;
}


.wrap .right{
    flex: 1;
    max-width: 250px;
    min-width: 250px;
}

.wrap .center{
    overflow: auto;
    /*margin: 15px 0 15px 15px;*/
    flex: 1;
    position: relative;
}

.wrap .center .centerPlaceholder{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: var(--backgroundColorOpacity60);
    z-index: 200;
    /*blur the background*/
    backdrop-filter: blur(5px);
    border-radius: 7px;
}

.wrap .center .centerPlaceholder .centerPlaceholderText{
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-items: center;
    text-align: center;
    color: var(--text-base);
    padding: 20px;
    max-width: 350px;
    border-radius: 10px;
    border: 1px solid var(--darkAccents-7);
    background: var(--backgroundColor);
}

.wrap .center .centerPlaceholder .centerPlaceholderText h3{
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 0;
}

.wrap .center .centerPlaceholder .centerPlaceholderText p{
    margin: 0;
    font-size: 14px;
}

.row .right .sticky{
    position: sticky;
    width: 100%;
    top: 0px;
}
.closeBanner{
    position: absolute;
    right: 10px;
    display: flex;
    top: 10px;
    z-index: 2;
}


.topLine{
    display: flex;
    padding: 0 20px;
    align-items: center;
    max-width: 80%;
    overflow: auto;
}

.topLine .separator{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--darkAccents-5);
    margin: 0 10px;
    line-height: 24px;
    font-size: 18px;
}

.leadValue{
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.statusWrap{
    margin-right: 10px;
}

.wrapConfirm{
    flex-direction: column;
    display: flex;
}

.wrapConfirm .noRollback{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
}
